%cover {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

%img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@mixin placeholder {
  &.placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin cursor {
  @media (any-pointer: fine) {
    @content;
  }
}

@mixin cursor-mobile {
  @media (pointer: coarse) {
    @content;
  }

  @include media(">tablet") {
    @content;
  }
}

@mixin ratio($height, $width) {
  font-size: 0;
  overflow: hidden;
  position: relative;
  aspect-ratio: calc($width / $height);

  @supports not (aspect-ratio: calc($width / $height)) {
    &:after {
      content: "";
      display: inline-block;
      padding-top: calc($height / $width) * 100%;
      width: 100%;
    }
  }

  img,
  video {
    @extend %img;
    position: absolute;
    top: 0;
    left: 0;
  }
}
