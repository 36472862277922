$black: #000000;
$white: #ffffff;
$orange: #cb450c;
$boulder: #777777;
$gray: #aaaaaa;

$font-bebas: "Bebas Neue", sans-serif;
$font-sauce: "Open Sauce", arial;

$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);

$z-indexes: ("modal", "overlay", "5", "preloader", "4", "3", "2", "1");
