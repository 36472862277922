.c-home {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    z-index: z("3");
    padding: 24px;
    opacity: 0;

    &.active {
      opacity: 1;
    }

    &.enter {
      .c-song__cover {
        transition: transform 350ms ease-in-out, opacity 350ms ease-in-out;
        transform: scale(1);
        opacity: 1;
      }

      .c-song__artist,
      .c-song__title,
      .c-song__button {
        transition: transform 350ms ease-in-out, opacity 350ms ease-in-out;
        transform: translateY(0) scale(1);
        opacity: 1;
      }
    }

    .c-song {
      &__cover {
        max-width: 240px;
        width: 100%;
        @include ratio(1, 1);
        margin-bottom: 16px;
        border-radius: 4px;
        overflow: hidden;
        transform: scale(0.8333333333);
        transition: none;
        opacity: 0;

        &:hover {
          transition: transform 350ms ease-in-out, opacity 350ms ease-in-out;
          transform: translateY(0) scale(1.02);
        }
      }

      &__artist {
        text-transform: uppercase;
        margin-bottom: 8px;
        transition: none;
        transform: translateY(-4px);
        opacity: 0;
      }

      &__title {
        font-weight: 400;
        font-size: 48px;
        line-height: 90%;
        font-family: $font-bebas;
        text-align: center;
        max-width: 360px;
        margin-bottom: 16px;
        transition: none;
        transform: translateY(4px) scale(1.1);
        opacity: 0;

        @include media("<=tablet") {
          font-size: 32px;
          max-width: 240px;
        }
      }

      &__button {
        transition: none;
        transform: translateY(8px);
        opacity: 0;
      }

      &__play {
      }
    }
  }

  &__progress {
    width: 4px;
    left: 0;
    position: absolute;
    height: 100%;
    background: $orange;
    z-index: z("2");
    opacity: 0;
    transition: transform 0.2s linear;

    &.active {
      opacity: 1;
    }
  }

  &__blur,
  &__background,
  &__video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &__blur {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(80px);
    z-index: z("1");
  }

  &__background {
    transition: none;
    transform: scale(1.1);

    img {
      @extend %img;
    }

    &.enter {
      transition: transform 350ms ease-in-out;
      transform: scale(1);
    }
  }

  &__video {
    z-index: z("4");
    video {
      @extend %img;
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.c-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: z("5");
  text-transform: uppercase;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 24px 40px;

  @include media("<=tablet") {
    padding: 24px;
  }

  &__location {
    color: rgba($white, 0.75);
  }

  &__time {
    display: flex;
    justify-content: flex-end;
  }

  &__name,
  &__right {
    @include media("<=tablet") {
      display: none;
    }
  }

  &__indicator {
    display: flex;
    padding: 2px 6px;
    background: rgba($white, 0.9);
    color: $black;
    align-items: center;
    border-radius: 4px;
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translate(-50%, 0);
    line-height: 1;
    height: 20px;

    .live {
      display: none;
      align-items: center;

      .dot {
        width: 8px;
        height: 8px;
        background: $orange;
        margin-left: 2px;
        border-radius: 50%;
      }
    }

    .offline {
      display: flex;
      align-items: center;
    }

    &.live {
      .live {
        display: flex;
      }

      .offline {
        display: none;
      }
    }
  }
}

.c-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: z("5");
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 24px 40px;

  @include media("<=tablet") {
    padding: 24px;
  }
}

.c-animatedbutton {
  display: inline-block;
  background: transparent;
  color: rgba($white, 0.9);
  font-size: 16px;
  text-transform: uppercase;
  padding: 5px 8px;
  border: 2px solid rgba($white, 0.9);
  border-radius: 45px;
  cursor: pointer;
  transition: all 0.3s;

  .c-link {
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &__inner {
      display: flex;
      transform: translateY(0);
      // transition: transform 0.3s ease-out;
      transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      margin-right: 2px;

      > span {
        /* white-space: nowrap; */
      }
    }

    &__animated {
      /* white-space: nowrap; */
      position: absolute;
      top: 100%;
      display: none;
      font-family: $font-sauce;

      @media (any-pointer: fine) {
        display: inline-block;
      }
    }
  }

  &:hover {
    @media (any-pointer: fine) {
      .c-link__inner {
        transform: translateY(-100%);
      }
    }
  }
}

.c-button {
  font-family: $font-sauce, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  background: transparent;
  border: 0;
  color: inherit;
  @extend %link;
  padding: 0;
  cursor: pointer;
}

.c-modal {
  position: fixed;
  width: 100%;
  z-index: z("overlay");
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;

  &.active {
    pointer-events: auto;

    .c-modal__content {
      transform: translateY(0);
    }

    .c-modal__backdrop {
      opacity: 1;
      pointer-events: auto;
    }

    .c-open {
      pointer-events: none;
      opacity: 0;
    }

    .c-close {
      pointer-events: auto;
      opacity: 1;
    }
  }

  &__buttons {
    display: grid;

    > div {
      grid-area: 1/1;
    }

    .c-open {
      transition: opacity 175ms ease-in-out;
      opacity: 1;
      pointer-events: auto;
    }

    .c-close {
      transition: opacity 175ms ease-in-out;
      opacity: 0;
      pointer-events: none;
    }
  }

  &__link {
    pointer-events: auto;
  }

  &__button {
    display: inline-block;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 40px;
    pointer-events: none;
    padding: 0 40px;

    @include media("<=tablet") {
      padding: 0 24px;
    }
  }

  &__backdrop {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    opacity: 0;
    transition: opacity 350ms ease-in-out;
  }

  &__content {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: z("modal");
    transform: translateY(calc(100% - 40px));
    transition: none;
    pointer-events: none;
  }

  &__main {
    background: $white;
    color: rgba(0, 0, 0, 0.75);
    padding: 32px 40px;
    pointer-events: auto;

    @include media("<=tablet") {
      padding: 24px;
    }

    p {
      display: inline;

      @include media("<=tablet") {
        display: block;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
  }
}

.c-preloader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: z("preloader");
  background: $black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 48px;

  &__content {
    margin-bottom: 32px;
    text-align: center;
    max-width: 640px;

    p {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
