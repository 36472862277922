html {
  font-size: calc(100vw / 1440 * 10);
  overscroll-behavior: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overscroll-behavior: none;
}

body {
  font-family: $font-sauce, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  overscroll-behavior: none;
  color: rgba($white, 0.9);
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.flash {
  animation: blinker 1s step-start infinite;
}
