.c-home__video video, .c-home__background img, .c-home__main .c-song__cover img, .c-home__main .c-song__cover video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

@font-face {
  font-family: Open Sauce;
  src: url("opensauce-medium.638d09c7.woff2") format("woff2"), url("opensauce-medium.638d09c7.woff2") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Open Sauce;
  src: url("opensauce-regular.f5d13f60.woff2") format("woff2"), url("opensauce-regular.f5d13f60.woff2") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

html {
  overscroll-behavior: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overscroll-behavior: none;
  font-size: .694444vw;
}

body {
  overscroll-behavior: none;
  color: #ffffffe6;
  font-family: Open Sauce, arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.flash {
  animation: 1s step-start infinite blinker;
}

.c-button {
  vertical-align: top;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.c-button:after {
  content: "";
  height: 1px;
  width: 100%;
  transform-origin: 0;
  background: currentColor;
  transition: transform .7s cubic-bezier(.19, 1, .22, 1);
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(1);
}

.c-button:hover:after {
  transform-origin: 100%;
  transform: scaleX(0);
}

.c-button {
  display: inline-block;
}

.c-home {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.c-home__main {
  height: 100%;
  z-index: 3;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  display: flex;
  position: relative;
}

.c-home__main.active {
  opacity: 1;
}

.c-home__main.enter .c-song__cover {
  opacity: 1;
  transition: transform .35s ease-in-out, opacity .35s ease-in-out;
  transform: scale(1);
}

.c-home__main.enter .c-song__artist, .c-home__main.enter .c-song__title, .c-home__main.enter .c-song__button {
  opacity: 1;
  transition: transform .35s ease-in-out, opacity .35s ease-in-out;
  transform: translateY(0)scale(1);
}

.c-home__main .c-song__cover {
  max-width: 240px;
  width: 100%;
  aspect-ratio: 1;
  opacity: 0;
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 0;
  transition: none;
  position: relative;
  overflow: hidden;
  transform: scale(.833333);
}

@supports not (aspect-ratio: calc(1 / 1)) {
  .c-home__main .c-song__cover:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.c-home__main .c-song__cover img, .c-home__main .c-song__cover video {
  position: absolute;
  top: 0;
  left: 0;
}

.c-home__main .c-song__cover:hover {
  transition: transform .35s ease-in-out, opacity .35s ease-in-out;
  transform: translateY(0)scale(1.02);
}

.c-home__main .c-song__artist {
  text-transform: uppercase;
  opacity: 0;
  margin-bottom: 8px;
  transition: none;
  transform: translateY(-4px);
}

.c-home__main .c-song__title {
  text-align: center;
  max-width: 360px;
  opacity: 0;
  margin-bottom: 16px;
  font-family: Bebas Neue, sans-serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 90%;
  transition: none;
  transform: translateY(4px)scale(1.1);
}

@media (max-width: 768px) {
  .c-home__main .c-song__title {
    max-width: 240px;
    font-size: 32px;
  }
}

.c-home__main .c-song__button {
  opacity: 0;
  transition: none;
  transform: translateY(8px);
}

.c-home__progress {
  width: 4px;
  height: 100%;
  z-index: 2;
  opacity: 0;
  background: #cb450c;
  transition: transform .2s linear;
  position: absolute;
  left: 0;
}

.c-home__progress.active {
  opacity: 1;
}

.c-home__blur, .c-home__background, .c-home__video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.c-home__blur {
  -webkit-backdrop-filter: blur(80px);
  backdrop-filter: blur(80px);
  z-index: 1;
  background: #00000080;
}

.c-home__background {
  transition: none;
  transform: scale(1.1);
}

.c-home__background.enter {
  transition: transform .35s ease-in-out;
  transform: scale(1);
}

.c-home__video {
  z-index: 4;
}

.c-home__video.hidden {
  opacity: 0;
  pointer-events: none;
}

.c-nav {
  z-index: 6;
  text-transform: uppercase;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 24px 40px;
  display: flex;
  position: absolute;
  top: 0;
}

@media (max-width: 768px) {
  .c-nav {
    padding: 24px;
  }
}

.c-nav__location {
  color: #ffffffbf;
}

.c-nav__time {
  justify-content: flex-end;
  display: flex;
}

@media (max-width: 768px) {
  .c-nav__name, .c-nav__right {
    display: none;
  }
}

.c-nav__indicator {
  color: #000;
  height: 20px;
  background: #ffffffe6;
  border-radius: 4px;
  align-items: center;
  padding: 2px 6px;
  line-height: 1;
  display: flex;
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translate(-50%);
}

.c-nav__indicator .live {
  align-items: center;
  display: none;
}

.c-nav__indicator .live .dot {
  width: 8px;
  height: 8px;
  background: #cb450c;
  border-radius: 50%;
  margin-left: 2px;
}

.c-nav__indicator .offline {
  align-items: center;
  display: flex;
}

.c-nav__indicator.live .live {
  display: flex;
}

.c-nav__indicator.live .offline {
  display: none;
}

.c-footer {
  z-index: 6;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 24px 40px;
  display: flex;
  position: absolute;
  bottom: 0;
}

@media (max-width: 768px) {
  .c-footer {
    padding: 24px;
  }
}

.c-animatedbutton {
  color: #ffffffe6;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border: 2px solid #ffffffe6;
  border-radius: 45px;
  padding: 5px 8px;
  font-size: 16px;
  transition: all .3s;
  display: inline-block;
}

.c-animatedbutton .c-link {
  z-index: 1;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.c-animatedbutton .c-link__inner {
  margin-right: 2px;
  transition: transform .3s cubic-bezier(.175, .885, .32, 1.275);
  display: flex;
  transform: translateY(0);
}

.c-animatedbutton .c-link__animated {
  font-family: Open Sauce, arial;
  display: none;
  position: absolute;
  top: 100%;
}

@media (any-pointer: fine) {
  .c-animatedbutton .c-link__animated {
    display: inline-block;
  }

  .c-animatedbutton:hover .c-link__inner {
    transform: translateY(-100%);
  }
}

.c-button {
  color: inherit;
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  font-family: Open Sauce, arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
}

.c-modal {
  width: 100%;
  z-index: 7;
  height: 100%;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
}

.c-modal.active {
  pointer-events: auto;
}

.c-modal.active .c-modal__content {
  transform: translateY(0);
}

.c-modal.active .c-modal__backdrop {
  opacity: 1;
  pointer-events: auto;
}

.c-modal.active .c-open {
  pointer-events: none;
  opacity: 0;
}

.c-modal.active .c-close {
  pointer-events: auto;
  opacity: 1;
}

.c-modal__buttons {
  display: grid;
}

.c-modal__buttons > div {
  grid-area: 1 / 1;
}

.c-modal__buttons .c-open {
  opacity: 1;
  pointer-events: auto;
  transition: opacity .175s ease-in-out;
}

.c-modal__buttons .c-close {
  opacity: 0;
  pointer-events: none;
  transition: opacity .175s ease-in-out;
}

.c-modal__link {
  pointer-events: auto;
}

.c-modal__button {
  display: inline-block;
}

.c-modal__header {
  height: 40px;
  pointer-events: none;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 40px;
  display: flex;
}

@media (max-width: 768px) {
  .c-modal__header {
    padding: 0 24px;
  }
}

.c-modal__backdrop {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #000000bf;
  transition: opacity .35s ease-in-out;
}

.c-modal__content {
  width: 100%;
  z-index: 8;
  pointer-events: none;
  transition: none;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(calc(100% - 40px));
}

.c-modal__main {
  color: #000000bf;
  pointer-events: auto;
  background: #fff;
  padding: 32px 40px;
}

@media (max-width: 768px) {
  .c-modal__main {
    padding: 24px;
  }
}

.c-modal__main p {
  display: inline;
}

@media (max-width: 768px) {
  .c-modal__main p {
    display: block;
  }

  .c-modal__main p:not(:last-child) {
    margin-bottom: 16px;
  }
}

.c-preloader {
  height: 100%;
  width: 100%;
  z-index: 5;
  background: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 48px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.c-preloader__content {
  text-align: center;
  max-width: 640px;
  margin-bottom: 32px;
}

.c-preloader__content p:not(:last-child) {
  margin-bottom: 24px;
}

/*# sourceMappingURL=index.5f0d917d.css.map */
